import { css } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import { UnreachableCaseError } from '@orus.eu/error'
import { ButtonLink, spacing, useAsyncCallback } from '@orus.eu/pharaoh'
import { isFailure } from '@orus.eu/result'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import { trpc } from '../../../client'
import { useNavigateToSubscriptionFunnel } from '../../../lib/hooks/use-navigate-to-subscription-funnel'
import { sessionManager, useSession } from '../../../lib/session'
import { GenericProblemMessage } from '../../organisms/generic-problem-message'
import { InvalidLinkMessage } from '../../organisms/invalid-link-message'
import { LoadingPage } from '../../templates/loading-page'
import { Page } from '../../templates/page'
import { WrongBrowserMessage } from './wrong-browser-message/wrong-browser-message'

type VerificationState =
  | { type: 'idle' | 'pending' | 'done' | 'no-pending-login' | 'invalid-token' }
  | { type: 'no-account'; email: string }

export default function LoginFinishPage(): JSX.Element {
  const navigate = useNavigate()
  const navigateToSubscriptionFunnel = useNavigateToSubscriptionFunnel()
  const session = useSession()
  const searchParams = useSearch({ strict: false })
  const [verification, setVerification] = useState<VerificationState>({ type: 'idle' })

  const token = searchParams.token
  const publicId = searchParams.login
  const redirect = searchParams.redirect
  const continueSubscriptionId = searchParams.continueSubscriptionId

  const finishEmailLogin = useAsyncCallback(async () => {
    if (!token) return

    setVerification({ type: 'pending' })

    const loginResult = await trpc.sessions.finishEmailLogin.mutate(token, {
      context: { requiresResponseHeadersOrBuffers: true },
    })

    if (loginResult === 'already-logged-in') {
      void navigate({ to: '/', replace: true })
      return
    }

    if (loginResult === 'no-pending-login' || loginResult === 'invalid-token') {
      setVerification({ type: loginResult })
      return
    }

    if (loginResult.type === 'no-account') {
      setVerification({ type: 'no-account', email: loginResult.email })
      return
    }

    setVerification({ type: 'done' })

    await sessionManager.refreshSession()

    if (redirect) {
      window.location.href = redirect
      return
    }

    if (continueSubscriptionId) {
      const continueSubscriptionResult =
        await trpc.subscriptions.continueSubscriptionAsCustomer.mutate(continueSubscriptionId)

      if (isFailure(continueSubscriptionResult)) {
        switch (continueSubscriptionResult.problem) {
          case 'contract-already-signed':
            void navigate({ to: '/', replace: true })
            return
          default:
            throw new UnreachableCaseError(continueSubscriptionResult.problem)
        }
      }
      navigateToSubscriptionFunnel(continueSubscriptionId)
      return
    }

    void navigate({ to: '/', replace: true })
  }, [continueSubscriptionId, navigate, navigateToSubscriptionFunnel, redirect, token])

  useEffect(() => {
    finishEmailLogin()
  }, [finishEmailLogin])

  if (session.loginPublicId !== publicId) {
    return (
      <Page>
        <WrongBrowserMessage />
      </Page>
    )
  }

  switch (verification.type) {
    case 'no-account':
      return <NoAccountPage email={verification.email} />
    case 'done': // wait for navigation
    case 'idle':
    case 'pending':
      return <LoadingPage />
    case 'invalid-token':
      return <InvalidLinkMessage />
    case 'no-pending-login':
      return (
        <GenericProblemMessage
          title="Lien expiré"
          principalMessage="Ce lien a expiré."
          firstSubText="Cela peut être dû à une délai trop long ou à une autre action sur le compte."
          secondSubText="Vous pouvez réessayer de vous connecter, ou nous contacter pour obtenir de l'assistance."
          buttonText="Réessayer"
          onButtonClick={() => void navigate({ to: '/login' })}
        />
      )
  }
}

type NoAccountPageProps = {
  email: string
}

function NoAccountPage({ email }: NoAccountPageProps): JSX.Element {
  return (
    <Page>
      <Box sx={{ padding: spacing[60], maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto' }}>
        <Typography variant="h3" sx={{ marginTop: spacing[60] }}>
          Vous n&apos;avez pas encore de compte Orus !
        </Typography>
        <Typography variant="body2" sx={{ marginTop: spacing[50] }} component="p">
          Nous n&apos;avons pas de compte client associé à l&apos;adresse {email}.
        </Typography>
        <ButtonLink
          variant="primary"
          to="/search"
          css={css`
            margin-top: ${spacing[80]};
          `}
          fullWidth
        >
          Souscrire à Orus
        </ButtonLink>
        <ButtonLink
          variant="secondary"
          to="/"
          css={css`
            margin-top: ${spacing[60]};
          `}
          fullWidth
        >
          Utiliser une autre adresse
        </ButtonLink>
      </Box>
    </Page>
  )
}
